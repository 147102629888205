import { api } from "../api";

export interface DummyApiProps {
  id?: number;
  name: string;
  responsedata: string;
}

export const getDummyApiList = async () => {
  return await api.get(`dummyapi`);
};

export const updateDummyApi = async ({ payload, id }: { payload: DummyApiProps; id: number }) => {
  return api.patch(`dummyapi/${id}`, undefined, payload);
};

export const createDummyApi = async ({ payload }: { payload: DummyApiProps }) => {
  return api.post(`dummyapi`, undefined, payload);
};

export const deleteDummyApi = async ({ id }: { id: number }) => {
  return api.delete(`dummyapi/${id}`);
}; 