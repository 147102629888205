import { MantineTheme } from '@mantine/core';

export const scrollbarStyle = (theme: MantineTheme) => ({
  '&::-webkit-scrollbar': {
    width: '8px',
    backgroundColor: 'transparent',
  },
  '&::-webkit-scrollbar-thumb': {
    background: theme.colorScheme === 'dark' 
      ? theme.colors.dark[4] 
      : theme.colors.gray[3],
    borderRadius: '4px',
    '&:hover': {
      background: theme.colorScheme === 'dark' 
        ? theme.colors.dark[3] 
        : theme.colors.gray[4],
    },
  },
  '&::-webkit-scrollbar-track': {
    backgroundColor: 'transparent',
  },
}); 