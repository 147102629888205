import { Button, Grid, Modal, TextInput, Textarea, ScrollArea } from "@mantine/core";
import { useForm } from "@mantine/form";
import { useEffect } from "react";
import { useMutation, useQueryClient } from "react-query";
import { createDummyApi, updateDummyApi, DummyApiProps } from "../../queries/dummyApiQueries";
import { scrollbarStyle } from '../../styles/scrollbar';

interface FormProps {
  isOpenModal: boolean;
  setOpenForm: (open: boolean) => void;
  onReset: () => void;
  data: DummyApiProps | null;
}

export const DummyApiForm = (props: FormProps) => {
  const queryClient = useQueryClient();

  const { mutate: mutUpdateDummyApi } = useMutation(updateDummyApi, {
    onSuccess: () => {
      queryClient.invalidateQueries('dummyapi');
      props.onReset();
    },
  });

  const { mutate: mutCreateDummyApi } = useMutation(createDummyApi, {
    onSuccess: () => {
      queryClient.invalidateQueries('dummyapi');
      props.onReset();
    },
  });

  const form = useForm({
    initialValues: {
      name: "",
      responsedata: "",
    } as DummyApiProps,
    validate: {
      name: (value) => (value.length < 2 ? 'Name must be at least 2 characters' : null),
      responsedata: (value) => (value.length < 1 ? 'Response is required' : null),
    },
  });

  useEffect(() => {
    if (props.data) form.setValues(props.data);
  }, [props.data]);

  return (
    <Modal
      opened={props.isOpenModal}
      onClose={() => {
        form.reset();
        props.setOpenForm(false);
      }}
      title={props.data?.id ? "Update Dummy API" : "Create Dummy API"}
      centered
      size="xl"
      closeOnClickOutside={false}
    >
      <form
        onSubmit={form.onSubmit((values) => {
          if (props.data?.id) {
            mutUpdateDummyApi({ payload: values, id: props.data.id });
          } else {
            mutCreateDummyApi({ payload: values });
          }
        })}
      >
        <Grid>
          <Grid.Col span={12}>
            <TextInput
              label="Name"
              placeholder="Enter API name"
              {...form.getInputProps('name')}
              required
            />
          </Grid.Col>
          
          <Grid.Col span={12}>
            <Textarea
              label="Response"
              placeholder="Enter API response"
              minRows={12}
              autosize
              {...form.getInputProps('responsedata')}
              required
              styles={(theme) => ({
                input: {
                  fontFamily: 'monospace',
                  fontSize: '14px',
                  minHeight: '250px',
                  maxHeight: '400px',
                  overflowY: 'auto',
                  resize: 'vertical',
                  ...scrollbarStyle(theme),
                },
              })}
            />
          </Grid.Col>

          <Grid.Col span={3} offset={6}>
            <Button
              fullWidth
              onClick={() => form.reset()}
              mt="md"
              color="red"
              variant="light"
            >
              Clear
            </Button>
          </Grid.Col>
          <Grid.Col span={3}>
            <Button fullWidth type="submit" mt="md" variant="light">
              Submit
            </Button>
          </Grid.Col>
        </Grid>
      </form>
    </Modal>
  );
}; 