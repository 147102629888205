import { useState } from "react";
import {
  Table,
  ScrollArea,
  Grid,
  Button,
  Divider,
  ActionIcon,
  Text,
  Modal,
  Code,
} from "@mantine/core";
import { Edit, Archive, Eye } from "tabler-icons-react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { deleteDummyApi, getDummyApiList, DummyApiProps } from "../../queries/dummyApiQueries";
import { DummyApiForm } from "./Form";

interface ApiResponse {
  status: boolean;
  data: DummyApiProps[];
}

const DummyApiView = () => {
  const [isOpenForm, setOpenForm] = useState(false);
  const [data, setData] = useState<DummyApiProps | null>(null);
  const [viewResponse, setViewResponse] = useState<string | null>(null);
 
  const queryClient = useQueryClient();

  const { mutate: mutDeleteDummyApi } = useMutation(deleteDummyApi, {
    onSuccess: () => {
      queryClient.invalidateQueries('dummyapi'); 
    },
  });

  const { isLoading, data: apiResponse } = useQuery<ApiResponse>(
    "dummyapi",
    getDummyApiList,
    {
      staleTime: 60000,
      refetchOnWindowFocus: false,
    }
  );

  const onUpdateData = (payload: DummyApiProps) => {
    setData(payload);
    setOpenForm(true);
  };

  const onResetForm = () => {
    setOpenForm(false);
    setData(null);
  };

  // Access the data array from the response
  const rows = (apiResponse?.data || []).map((row: DummyApiProps) => (
    <tr key={row.id}>
      <td>{row.id}</td>
      <td>{row.name}</td>
      <td style={{ maxWidth: '400px' }}>
        <Text lineClamp={2} style={{ cursor: 'pointer' }} onClick={() => setViewResponse(row.responsedata)}>
          {row.responsedata}
        </Text>
      </td>
      <td>
        <Grid columns={8}>
          <Grid.Col span={1}>
            <ActionIcon
              title="View Full Response"
              onClick={() => setViewResponse(row.responsedata)}
            >
              <Eye size={25} strokeWidth={2} color="blue" />
            </ActionIcon>
          </Grid.Col>
          <Grid.Col span={1}>
            <ActionIcon
              title="Update"
              onClick={() => onUpdateData({ ...row })}
            >
              <Edit size={25} strokeWidth={2} color="green" />
            </ActionIcon>
          </Grid.Col>
          <Grid.Col span={1}>
            <ActionIcon
              title="Delete"
              onClick={() => mutDeleteDummyApi({ id: Number(row.id) })}
            >
              <Archive size={25} strokeWidth={2} color="red" />
            </ActionIcon>
          </Grid.Col>
        </Grid>
      </td>
    </tr>
  ));

  if (isLoading) {
    return null;
  }

  return (
    <>
      <Grid justify="flex-end">
        <Grid.Col lg={1}>
          <Button
            variant="light"
            onClick={() => setOpenForm(true)}
          >
            Create
          </Button>
        </Grid.Col>

        <Grid.Col>
          <Divider />
          <ScrollArea
            offsetScrollbars
            style={{ height: 700, padding: 15 }}
            scrollHideDelay={1000}
          >
            <Table
              sx={{ minWidth: 800 }}
              verticalSpacing="xs"
              horizontalSpacing="xl"
            >
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Name</th>
                  <th>Response</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>{rows}</tbody>
            </Table>
          </ScrollArea>
        </Grid.Col>
      </Grid>
      <DummyApiForm
        isOpenModal={isOpenForm}
        setOpenForm={setOpenForm}
        onReset={onResetForm}
        data={data}
      />

      <Modal
        opened={!!viewResponse}
        onClose={() => setViewResponse(null)}
        title="Full Response"
        size="xl"
      >
        <ScrollArea h="calc(90vh - 100px)">
          <Code block>{viewResponse}</Code>
        </ScrollArea>
      </Modal>
    </>
  );
};

export default DummyApiView; 